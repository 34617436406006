import axios from 'axios';

// const base = "http://localhost:3000/api"
const base = "https://server.novaticagency.com/api"
const loginUrl = base + "/auth/login";
const registerUrl = base + "/register";
const software = base + "/softwares";
const activation = base + "/activations";
const user = base + "/users";
const contract = base + "/users/contracts";


//#region AUTH  ----------------------------------------------------------

export function login(formData) {
    return axios.post(loginUrl, formData);
}

export function register(formData) {
    return axios.post(registerUrl, formData);
}
//#endregion




// #region  // SOFTWARE  ----------------------------------------------------------

export function getAllSoftwares(index, size, searchTerm) {
    const query = searchTerm ? `?page=${index}&limit=${size}&filter.search=${searchTerm}` : `?page=${index}&limit=${size}`;
    return axios.get(`${software}${query}`);
}

export function getAllUSoftwareMin() {
    return axios.get(`${software}/all-min`);
}


export function createSoftware(formData) {
    return axios.post(software, formData);
}

export function updateSoftware(formData) {
    return axios.put(software + '/' + formData.id, formData);
}

export function deleteSoftware(id) {
    return axios.delete(software + '/' + id);
}

//#endregion

// #region  // ACTIVATION  ----------------------------------------------------------

export function getAllActivations(index, size, searchTerm) {
    const query = searchTerm ? `?page=${index}&limit=${size}&filter.search=${searchTerm}` : `?page=${index}&limit=${size}`;
    return axios.get(`${activation}${query}`);
}


export function createActivation(formData) {
    return axios.post(activation, formData);
}

export function updateActivation(formData) {
    return axios.put(activation + '/' + formData.id, formData);
}

export function activate(formData) {
    return axios.put(activation + '/' + formData.id + '/activate');
}

export function deleteActivation(id) {
    return axios.delete(activation + '/' + id);
}

//#endregion

// #region  // USER  ----------------------------------------------------------

export function getAllUsers(index, size, searchTerm) {
    const query = searchTerm ? `?page=${index}&limit=${size}&filter.search=${searchTerm}` : `?page=${index}&limit=${size}`;
    return axios.get(`${user}${query}`);
}
export function getAllUsersMin() {
    return axios.get(`${user}/all-min`);
}


export function createUser(formData) {
    return axios.post(user, formData);
}

export function updateUser(formData) {
    return axios.put(user + '/' + formData.id, formData);
}

export function deleteUser(id) {
    return axios.delete(user + '/' + id);
}

//#endregion

// #region  // CONTRACT  ----------------------------------------------------------

export function getAllContracts(index, size, searchTerm) {
    const query = searchTerm ? `?page=${index}&limit=${size}&filter.search=${searchTerm}` : `?page=${index}&limit=${size}`;
    return axios.get(`${contract}${query}`);
}


export function createContract(formData) {
    return axios.post(contract, formData);
}

export function updateContract(formData) {
    return axios.put(contract + '/' + formData.id, formData);
}

export function deleteContract(id) {
    return axios.delete(contract + '/' + id);
}

//#endregion


// #region  // HELPERS  ----------------------------------------------------------

export function isObjectEmpty(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

function formatDateTime(dateTime) {
    const date = new Date(dateTime)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


//#endregion

