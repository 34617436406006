<template>
    <div class="modal fade confirmModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm operation</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center">
                    <h4>Are you sure ?</h4>
                    <h6>you want to proceed</h6>
                </div>
                <div v-show="type == 'cancel'" class="modal-footer py-1" style="justify-content:space-between">
                    <div class="custom-control custom-checkbox custom-checkbox-danger">
                        <input type="checkbox" class="custom-control-input" id="customCheckcolor5" v-model="checked"
                            @change="onChange" />
                        <label class="custom-control-label font-size-14" for="customCheckcolor5">No Response ?</label>
                    </div>

                    <div>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                        <button type="button" class="confirm btn btn-danger">Yes</button>
                    </div>
                </div>

                <div v-show="type != 'cancel' && type != 'delete'" class="modal-footer py-1">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    <button type="button" class="confirm btn btn-primary">Yes</button>
                </div>

                <div v-show="type == 'delete'" class="modal-footer py-1">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    <button type="button" class="confirm btn btn-danger">Yes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["type"],

    data() {
        return {
            checked: false
        };
    },
    methods: {
        onChange: function () {
            this.$emit("onCheckboxChange", this.checked);
        },
    },
};
</script>
