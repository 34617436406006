import axios from 'axios';

import Vue from 'vue'
import App from './App.vue'
import router from './router.js';
import store from "./store";

import PageHeader from './components/shared/page-header.vue';
import ConfirmModal from './components/shared/confirm-modal.vue';
import DeleteModal from './components/shared/delete-modal.vue';

import * as api from './utils/api.js';
import fullscreen from 'vue-fullscreen'
import VueEasytable from "vue-easytable";
import { VePagination } from "vue-easytable";
import { veLoading } from "vue-easytable";
import ToastPlugin from 'vue-toast-notification';
import { ModelListSelect } from 'vue-search-select'

import './style.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import "vue-easytable/libs/theme-default/index.css";
import 'vue-search-select/dist/VueSearchSelect.css'


Vue.prototype.$api = api;
Vue.prototype.$veLoading = veLoading;
Vue.config.productionTip = false;

Vue.use(fullscreen)
Vue.use(VueEasytable);
Vue.use(VePagination);
Vue.use(ToastPlugin, { duration: 5600 });
Vue.component('select-list', ModelListSelect);

// SHARED COMPONENETS
Vue.component('page-header', PageHeader);
Vue.component('confirm-modal', ConfirmModal);
Vue.component('delete-modal', DeleteModal);


// Add a request interceptor
axios.interceptors.request.use((config) => {
  let token = localStorage.getItem('token')
  if (token) { config.headers['Authorization'] = `Bearer ${token}` }
  return config;
},
  (error) => {
    return Promise.reject(error);
  });

// AUTH GUARD
axios.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    // clearing data
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.$store.commit('setUpdateUser', null);
    router.push('/login');
  }
  return Promise.reject(error);
}
);


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
