import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: { user: false },
    getters: {},
    actions: {},
    mutations: {
        setUpdateUser(state, user) {
            state.user = user;
        },
    },
});
