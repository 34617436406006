import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/software',
        component: () => import('./components/main.vue'),
        meta: { requiresAuth: true },
        children: [
            { path: '', component: () => import('./components/pages/software') },
            { path: 'software', component: () => import('./components/pages/software') },
            { path: 'activation', component: () => import('./components/pages/activation') },
            { path: 'user', component: () => import('./components/pages/user') },
            { path: 'contract', component: () => import('./components/pages/contract') },
        ],
    },
    {
        path: '/login',
        component: () => import('./components/public'),
        meta: { isGuest: true },
        children: [
            { path: '', component: () => import('./components/pages/auth/login') },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/software',
    },
];

function loggedIn() {
    return localStorage.getItem('token');
}



const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'highlight',
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn()) {
            next({ path: '/login' })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (loggedIn()) {
            next({ path: '/dashboard' })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router;

