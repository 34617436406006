<template>
    <div class="row align-items-center">
        <div class="col-3">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0 mt-1 ">
                    <i v-bind:class="[iconClass]" class="mr-2 font-size-18"></i>
                    {{ title }}
                </h4>
            </div>
        </div>

        <div class="col-4 offset-5">
            <div class="text-right">
                <button v-if="btnText" type="button"
                    class="font-size-10 btn btn-primary btn-rounded waves-effect btn-label waves-light mb-4" @click="click">
                    <i class="mdi mdi-plus label-icon mr-1"></i>
                    {{ btnText }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["title", "btn", "btn2"],

    data() {
        return {
            btnText: null,
            btnText2: null,
            iconClass: null
        };
    },
    mounted() {
        if (this.btn) this.btnText = this.btn;
        if (this.btn2) this.btnText2 = this.btn2;
        this.setIcon();
    },

    methods: {
        click() { this.$emit("click") },
        click2() { this.$emit("click2") },

        setIcon() {
            switch (this.title) {
                case 'softwares':
                    this.iconClass = 'bx bx-cube';
                    break;
                case 'activations':
                    this.iconClass = 'mdi mdi-desktop-mac-dashboard';
                    break;
                case 'users':
                    this.iconClass = 'bx bx-user';
                    break;
                case 'contracts':
                    this.iconClass = 'mdi mdi-file-document';
                    break;
            }
        }
    },
};
</script>

<style >
button {
    font-size: 11px !important;
    padding: 4px 8px;
}
</style>
